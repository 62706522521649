import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { PreferenceDto } from '../dtos';
import { GET_PREFERENCE_QUERY, SAVE_PREFERENCES_MUTATION } from '../graphql';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(private readonly apollo: Apollo) {}

  savePreferences(preferenceRequest: PreferenceDto) {
    return this.apollo.mutate({
      mutation: SAVE_PREFERENCES_MUTATION,
      variables: {
        preferenceRequest,
      },
    });
  }

  getPreference(
    contactId: string,
    objectType: string,
    objectName: string,
    pageName: string,
  ) {
    return this.apollo
      .query({
        query: GET_PREFERENCE_QUERY,
        variables: {
          contactId,
          objectType,
          objectName,
          pageName,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data?.preferences));
  }
}
